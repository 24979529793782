// 1. Import Bootstrap default functions and variables
@import "bootstrap/scss/functions";
@import "variables.scss";
@import "bootstrap/scss/variables";

// 2. Define our own variables (and Bootstrap variable overrides)
// note that with UF-7375 a consistent Bootstrap theme has been discarded
// and these styles just set classes and colors directly

$faces-colors: (
  "primary": $faces-main,
  "secondary": #8f8d80,
  "success": $faces-main,
  "danger": #ed1c24
);

$theme-colors: map-merge($theme-colors, $faces-colors);
$body-bg: #f1f2f2;
$body-color: #1c6666;

// Bootstrap and its default variables
@import 'bootstrap/scss/bootstrap';
@import 'extensions.scss';

:root {
  color-scheme: light;
}

.app-container {
  .nav-banner {
    background-color: white;
  }
}

.navbar-light {
  .navbar-nav {
    .nav-link {
      color: $faces-main;
    }

    .nav-link.nav-active {
      color: #1c6666;
    }
  }
}

.navigation-outline.active {
  color: #1c6666;
}

h3, h4 {
  color: $faces-main;
}

.col-form-label {
  color: #1c6666;
}

.publishing-error {
  color: #ed1c24;
}

.publishing-read-pending {
  color:  #eeb20f;
}

.card {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.background-reset {
  background-color: #fff;
}

.row-striped:nth-of-type(even) {
  background: #f9f9f9;
}

.dropdown-menu {
  background-color: #fff;
}

.form-control::placeholder {
  color: #a3b6b6;
}

.form-control,
.form-select {
  background-color: #fff;;
  color: $body-color;
}

.form-control:disabled,
.form-select:disabled {
  background-color: #f1f2f2;
  color: #a3b6b6;
}

div, span {
  .form-control.disabled {
    background-color: #f1f2f2;
    border-color: #a3b6b6;
  }
}

.grid-striped .row:nth-of-type(odd) {
  background-color: rgba(0,0,0,.05);
}

.alert-danger {
  color: #e3527b;
  background-color: #f6dee6;
  border-color: #e3527b;

  .btn-close {
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23e3527b'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  }
}

.alert-success {
  color: #3bc1ca;
  background-color: #e6f5f6;
  border-color: #3bc1ca;

  .btn-close {
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%233bc1ca'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  }

}

/* Overwrite color of expanded headers */
.accordion-button:not(.collapsed) {
  background-color: #b1e6ea ;
}

.accordion-button:focus {
    border-color: #b1e6ea;
    box-shadow: none;
}

.nav-tabs {
  .nav-link {
    color: #c2c0bf;
  }

  .nav-link.active {
    color: #1c6666;
  }
}

.modal-dialog .modal-content {
  background-color: white;
}

.btn-delete, .btn-danger, .btn-danger:hover  {
  color: white;
  background-color: #1c6666;
  border-color: #1c6666;
}

.btn-delete.btn-icon  {
  color: #1c6666;
  background-color: white;
  border-color: white;
}

.btn-primary {
  color: white;
  background-color: $faces-main;
  border-color: $faces-main;
}

.btn-secondary {
  color: #1c6666;
  background-color: #e2e6e6;
  border-color: #e2e6e6;
}

input.ng-dirty, select.ng-dirty {
  background-color: #e6f5f6;
}

input.ng-invalid,
textarea.ng-invalid,
select.ng-invalid,
app-datepicker.ng-invalid > div > input {
  background-color: #f5dce4;
}

.error-text {
  color: #e9d4ce;
}

.pseudo-link {
  color: blue;
}

